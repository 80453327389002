/**
 * Created by iamso on 12.08.2020.
 */

var DiscoverRocket = Rocket.extend({
    ctor: function (startPos, targetPos, area, showFinger) {
        cc.Node.prototype.ctor.call(this);

        var styles = cleverapps.styles.DiscoverRocket;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);
        this.setPositionRound(startPos);
        var animation = this.animation = new cleverapps.Spine(bundles.game.jsons.discover_action_json);

        animation.setAnimation(0, "animation", false);
        animation.addAnimation(0, "idle", true);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);

        this.prevPos = startPos;
        this.scheduleUpdate();

        area.hinted();
        area.terminateHintListeners.push(this.createListener(this.terminate.bind(this)));
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, targetPos).easing(cc.easeOut(2)),
            new cc.CallFunc(function () {
                if (showFinger) {
                    FingerView.hintTap(this);
                }
            }.bind(this)),
            new cc.DelayTime(5),
            new cc.CallFunc(area.terminateHints.bind(area))
        ));
    },

    terminate: function () {
        this.runAction(new cc.RemoveSelf());
    }
});

cleverapps.styles.DiscoverRocket = {
    width: 100,
    height: 100
};
