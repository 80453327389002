/**
 * Created by vladislav on 20.02.2020
 */

var WeeklyCupView = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.WeeklyCupView;

        var timerBlock = this.timerBlock = this.createTimerBlock();
        var tableView = this.tableView = this.createTable();

        var content = this.content = new cleverapps.Layout([timerBlock, tableView], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
        this.addChild(content);

        this.setAnchorPoint(0.5, 0.5);

        this.background = cleverapps.UI.createScale9Sprite(bundles.windows.frames.window_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.background.setContentSize2(content.width, content.height);
        this.addChild(this.background, -1);

        this.title = this.createTitle();

        this.helpButton = this.createHelpButton();
        this.addChild(this.helpButton);

        this.adjustPosition();

        this.updateState();

        cleverapps.weeklyCup.reload();

        cleverapps.weeklyCup.on("update", this.createListener(this.updateState.bind(this)), this);
    },

    updateSize: function () {
        this.content.reshape();
        this.adjustPosition();
    },

    updateState: function () {
        if (this.animationRunning) {
            return;
        }

        if (cleverapps.weeklyCup.isRunning()) {
            this.timerBlock.setVisible(true);
            this.timer.countDown.resetTimeLeft(cleverapps.weeklyCup.getTimeLeft());
        } else {
            this.timerBlock.setVisible(false);
        }

        if (cleverapps.weeklyCup.getReward()) {
            this.receiveReward();
        }
    },

    createHelpButton: function () {
        return new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_light,
            callback: function () {
                cleverapps.focusManager.display({
                    focus: "KnockoutRulesWindow",
                    action: function (f) {
                        new KnockoutRulesWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                });
            } 
        });
    },

    createTitle: function () {
        var styles = cleverapps.styles.WeeklyCupView.title;

        var bg = new cc.Scale9Sprite(bundles.main.frames.window_title_bg_png);
        bg.setAnchorPoint(0.5, 0.5);
        bg.setContentSize2({
            width: styles.width,
            height: bg.height
        });

        var title = cleverapps.UI.generateOnlyText("Cups.weekly.title", cleverapps.styles.FONTS.WINDOW_TITLE_TEXT);
        title.fitTo(styles.text.width);
        title.setPositionRound(styles.text);
        bg.addChild(title);

        bg.setPositionRound(styles);
        this.addChild(bg);

        return bg;
    },

    createTimerBlock: function () {
        var styles = cleverapps.styles.WeeklyCupView.timer;

        var leftTime = cleverapps.weeklyCup.getTimeLeft();
        var timer = this.timer = new cleverapps.CountDownView(new cleverapps.CountDown(leftTime), {
            font: cleverapps.styles.FONTS.WHITE_TEXT
        });

        var text = cleverapps.UI.generateOnlyText("WeeklyCupTable.timerText", cleverapps.styles.FONTS.WHITE_TEXT);

        return new cleverapps.Layout([text, timer], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    createTable: function () {
        var styles = cleverapps.styles.WeeklyCupView.table;

        return new CupTableView(cleverapps.weeklyCup, function () {
            return new TableView(cleverapps.weeklyCup.table, {
                id: cleverapps.weeklyCup.type,
                data: cleverapps.weeklyCup.listParticipants(),
                rowOptions: {
                    dataIcon: bundles.tablerow_icons.frames.weeklycup,
                    dynamicWidth: true
                },
                height: styles
            });
        }, {
            scaleEasing: cc.easeIn(2)
        });
    },

    receiveReward: function () {
        cleverapps.focusManager.display({
            focus: "WeeklyCupRewards",
            actions: [
                function (f) {
                    this.animationRunning = true;

                    var place = cleverapps.weeklyCup.calcPlace();
                    new WeeklyCupVictoryWindow(place);
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this),

                function (f) {
                    cleverapps.weeklyCup.receiveReward(f);
                },

                function (f) {
                    f();
                    this.animationRunning = false;
                    this.updateState();
                }.bind(this)
            ]
        });
    },

    adjustPosition: function () {
        var styles = cleverapps.styles.WeeklyCupView;

        this.setContentSize2(this.content.getContentSize());

        this.setScale(styles.scale[cleverapps.resolution.mode]);
        this.baseScale = this.scale;
        this.background.setContentSize2(this.getContentSize());
        this.background.setPositionRound(this.width / 2, this.height / 2);
        this.content.setPositionRound(this.width / 2, this.height / 2);
        this.helpButton.setPositionRound(styles.helpButton);
    }
});

cleverapps.styles.WeeklyCupView = {
    margin: 10,

    scale: [0.5, 1, 1],

    padding: {
        x: 20,
        top: -20,
        bottom: 80
    },

    timer: {
        margin: 10
    },

    title: {
        x: { align: "center" },
        y: { align: "top", dy: 40 },
        width: 638,
        text: {
            width: 580,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 7 }
        }
    },

    helpButton: {
        x: { align: "right", dx: -40 },
        y: { align: "top", dy: -80 }
    },

    table: [{ height: 800 }, { height: 560 }, { height: 560 }]
};