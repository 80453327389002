/**
 * Created by mac on 7/15/20
 */

var PhotoViews = cc.Node.extend({
    ctor: function (differences, gameScene) {
        this._super();
        this.differences = differences;
        this.gameScene = gameScene;

        this.blurred = false;
        this.clovers = [];
        this.createImages();
        this.createClovers();

        this.photos = new cleverapps.Layout([this.imageA, this.imageB], this.getOptions());
        this.addChild(this.photos);

        differences.areas.forEach(function (area) {
            if (area.discovered) {
                var frameInfo = PhotoViews.FrameSizeAndPos(area);
                this.drawArea(this.imageA, frameInfo, true);
                this.drawArea(this.imageB, frameInfo, true);
            }
        }, this);

        this.createFrame();

        this.updateSize();
        this.setupChildren();

        differences.on("mistake", this.mistake.bind(this));
        differences.on("discover", this.discover.bind(this));
        differences.on("paint", this.paint.bind(this));
        differences.on("complete", this.complete.bind(this));
        differences.on("booster_discover", this.boosterDiscover.bind(this));
        
        cleverapps.focusManager.registerControl("photos", this.createListener(function (visible, silent) {
            this.toggleBlur(!visible, undefined, silent);
        }.bind(this)));

        if (cleverapps.config.debugMode && !cleverapps.config.wysiwygMode) {
            var rate = cleverapps.UI.generateImageText(differences.level.content.rate, cleverapps.styles.FONTS.MENUBAR_TEXT);
            this.addChild(rate);
            rate.setPositionRound(this.width / 2, this.height / 2);
        }
    },

    updateSize: function () {
        var styles = cleverapps.styles.PhotoViews;
        this.photos.setOptions(this.getOptions());

        this.setContentSize(this.photos.getContentSize());
        var size = {
            width: this.width + styles.photoFrame.padding.x,
            height: this.height + styles.photoFrame.padding.y
        };
        this.frame.setContentSize(size);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.PhotoViews;
        this.frame.setPositionRound(this.width / 2 + styles.photoFrame.offset.x, this.height / 2 + styles.photoFrame.offset.y);
        this.photos.setPositionRound(this.width / 2, this.height / 2);
        this.completeAnimation && this.completeAnimation.setPositionRound(this.width / 2, this.height / 2);
    },

    getPhotoSize: function () {
        return this.imageA.getContentSize();
    },

    getOptions: function () {
        return {
            direction: cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.PhotoViews.margin
        };
    },

    onClick: function (imgId, point) {
        if (this.blurred
            || cleverapps.focusManager.isFocused()
            && !(cleverapps.tutorial.isActive() && cleverapps.tutorial.getActive().name === TutorialScenario.BASIC_TUTORIAL_NAME)) {
            return;
        }
       
        this.differences.clickPoint(imgId, point);
    },

    createImages: function () {
        this.imageA = new cc.Sprite(this.differences.getImage("a"));
        cleverapps.UI.onClick(this.imageA, function (touch) {
            var point = this.imageA.convertTouchToNodeSpace(touch);
            this.onClick("a", point);
        }.bind(this), {
            interactiveScale: false
        });

        this.imageB = new cc.Sprite(this.differences.getImage("b"));
        cleverapps.UI.onClick(this.imageB, function (touch) {
            var point = this.imageB.convertTouchToNodeSpace(touch);
            this.onClick("b", point);
        }.bind(this), {
            interactiveScale: false
        });

        [this.imageA, this.imageB].forEach(function (image) {
            image.clickAnimation = new cleverapps.Spine(bundles.game.jsons.click_json);
            image.addChild(image.clickAnimation);
        });
    },

    createClovers: function () {
        if (this.differences.getMissionType() !== Mission.TYPE_LETTER) {
            return;
        }

        this.differences.clovers.forEach(function (cloverData) {
            this.clovers.push(new PhotoClover(cloverData, this));
        }.bind(this));
    },

    createFrame: function () {
        var frame = this.frame = cleverapps.UI.createScale9Sprite(bundles.game.frames.photo_frame, cleverapps.UI.Scale9Rect.TwoPixelXY);
        frame.setLocalZOrder(-1);
        this.addChild(frame);
    },

    paint: function () {
        new PaintBrush(this);
    },

    discover: function (area, imgId, point, cb) {
        cleverapps.audio.playSound(bundles.game.urls.area_found_sfx);

        var image = imgId === "a" ? this.imageA : this.imageB;
        if (point) {
            image.clickAnimation.setPositionRound(point);
            image.clickAnimation.setAnimation(0, "animation", false);
        }

        var frameInfo = PhotoViews.FrameSizeAndPos(area);

        if (cb) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.CallFunc(cb)
            ));
        }

        [this.imageA, this.imageB].forEach(function (parent) {
            var startPos = this.gameScene.convertToNodeSpace(parent.convertToWorldSpace(PhotoViews.FrameSizeAndPos(area)));
            var target = this.gameScene.convertToNodeSpace(this.gameScene.playersView.convertToWorldSpace(this.differences.competition.onGetUserView().getPosition()));
            var scorePoint = new ScoreRocket(startPos, target);
            this.gameScene.addChild(scorePoint);

            this.drawArea(parent, frameInfo);
        }, this);
    },

    drawArea: function (parent, frameInfo, silent) {
        var frame = cleverapps.UI.createScale9Sprite(bundles.game.frames.found_area);
        parent.addChild(frame, 1);

        frame.setContentSize2(frameInfo);
        frame.setPositionRound(frameInfo);

        if (silent) {
            return;
        }

        frame.setScale(0.2);
        frame.setOpacity(0);

        var time = 0.4;
        frame.runAction(new cc.Spawn(
            new cc.FadeIn(time),
            new cc.ScaleTo(time, 1).easing(cc.easeBackOut())
        ));
    },

    mistake: function (image, point) {
        cleverapps.audio.playSound(bundles.game.urls.wrong_guess_sfx);

        var animation = new cleverapps.Spine(bundles.game.jsons.mistake_json);
        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();

        (image === "a" ? this.imageA : this.imageB).addChild(animation);

        animation.setPositionRound(point);
    },

    complete: function () {
        this.runAction(StandartAnimations.complete(this, { pulse: false }));
        cleverapps.focusManager.hideControlsWhileFocused("photos");

        cleverapps.audio.playSound(bundles.game.urls.victory_mark);
    },

    hide: function () {
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.4, 0.2).easing(cc.easeBackIn()),
            new cc.Hide()
        ));
    },

    boosterDiscover: function (options) {
        options = options || {};

        var button = this.gameScene.discoverButton;
        var area = options.area;
        var startPos = options.startPos;

        cleverapps.audio.playSound(bundles.game.urls.discovery_booster_sfx);

        [this.imageA, this.imageB].forEach(function (image) {
            startPos = startPos || this.gameScene.convertToNodeSpace(button.parent.convertToWorldSpace(button.getPosition()));
            var targetPos = this.gameScene.convertToNodeSpace(image.convertToWorldSpace(PhotoViews.FrameSizeAndPos(area)));

            var rocket = new DiscoverRocket(startPos, targetPos, area, options.finger);
            this.gameScene.addChild(rocket);
        }.bind(this));
    },

    toggleClovers: function () {
        this.clovers.forEach(function (clover) {
            clover.runAction(new cc.FadeTo(0.1, this.blurred ? 0 : clover.baseOpacity));
        }.bind(this));
    },

    isCloversForceAvailable: function () {
        if (this.clovers.length === 0 || this.blurred) {
            return false;
        }
        return cleverapps.forces.isAvailable(this, Forces.PHOTO_CLOVER);
    },

    showCloversForce: function () {
        var forceTarget = this.clovers[0];

        cleverapps.focusManager.displayWhenFreeFocus({
            focus: "PhotoCloverForce",
            control: ["rewardElementClover", "photos"],
            actions: [
                function (f) {
                    cleverapps.forces.create(forceTarget, Forces.PHOTO_CLOVER);
                    cleverapps.forces.onceForceClosed = f;
                }
            ]
        });
    },

    toggleBlur: function (blurred, callback, silent) {
        callback = callback || function () {};

        if (this.gameScene.coolDownNodeActive() || this.blurred === blurred) {
            callback();
            return;
        }

        this.blurred = blurred;
        this.toggleClovers();

        var time = silent ? 0 : 0.3;

        [this.imageA, this.imageB].forEach(function (image, ind) {
            cleverapps.UI.toggleBlur(image, {
                animate: {
                    type: cleverapps.UI.BLUR_ANIMATION.CURTAIN,
                    time: time,
                    steps: 30,
                    reverseCurtain: 1 - ind,
                    callback: function () {
                        if (ind === 1) {
                            callback();
                        }
                    }
                }
            });
        });
    }
});

PhotoViews.FrameSizeAndPos = function (area) {
    var size = cc.size(area.width * resolutionScale, area.height * resolutionScale);
    var pos = cc.p(area.x * resolutionScale, area.y * resolutionScale);

    return {
        width: size.width,
        height: size.height,
        x: pos.x,
        y: pos.y
    };
};

cleverapps.styles.PhotoViews = {
    margin: 20,

    photoFrame: {
        offset: {
            x: 0,
            y: -17
        },
        padding: {
            x: 50,
            y: 79
        }
    }
};
