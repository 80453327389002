/**
 * Created by andrey on 01.08.2022
 */

var HideAnimations = function (f) {
    if (this.game.outcome === GameBase.OUTCOME_VICTORY) {
        this.photos.complete();
    } else if (this.game.discoverBooster) {
        this.game.discoverBooster.stopHint();
    }

    this.runAction(new cc.Sequence(
        new cc.DelayTime(1),
        new cc.CallFunc(function () {
            this.playersView.hide();
            this.photos.hide();
        }.bind(this)),
        new cc.DelayTime(1),
        new cc.CallFunc(f)
    ));
};