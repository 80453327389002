/**
 * Created by olga on 27.01.2025
 */

var PhotosTutorial = function () {
    return new TutorialScenario(TutorialScenario.BASIC_TUTORIAL_NAME, {
        listSteps: function () {
            return [PhotosTutorial.createStep()];
        },
        isAvailable: function () {
            return Game.currentGame && !cleverapps.forces.isShown(Forces.KEYPAD.id);
        }
    });
};

PhotosTutorial.createStep = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var options = {
        onStart: function () {
            scene.photos.toggleBlur(false);
            var game = Game.currentGame;
            var force = Forces.KEYPAD;

            var area = game.getUndiscoveredAreas().sort(function (a, b) {
                return b.y - a.y;
            })[0];

            force.finger = function () {
                return {
                    path: [area]
                };
            };

            this.force = cleverapps.forces.create(scene.photos, force);
            cleverapps.tutorial.on("discover_difference", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
        }
    };

    return new TutorialStep(options);
};
