/**
 * Created by mac on 7/15/20
 */

var Inflamer = function () {
    this.value = 0;
    this.stage = 0;

    this.onBurst = function () {};
    this.onChange = function () {};

    var RATE = 10;
    this.amounts = Inflamer.RATE_SECONDS.map(function (seconds) {
        return 1 / (seconds * RATE);
    });

    this.interval = cleverapps.timeouts.setInterval(this.reduce.bind(this), 1000 / RATE);
};

Inflamer.RATE_SECONDS = [20, 10, 5];

Inflamer.prototype.reduce = function () {
    var prev = this.getValue();

    if (prev > 0) {
        this.value -= this.amounts[prev - 1];

        var next = this.getValue();

        if (next !== prev) {
            this.onChange(next);
        }
    }
};

Inflamer.prototype.add = function () {
    this.value++;

    this.wasMistake = true;

    if (this.getValue() > 3) {
        this.stage++;
        this.value = 0;
        this.onBurst();
    } else {
        this.onChange(this.getValue());
    }
};

Inflamer.prototype.getValue = function () {
    return Math.ceil(this.value);
};

Inflamer.prototype.stop = function () {
    if (this.interval) {
        cleverapps.timeouts.clearInterval(this.interval);
        this.interval = undefined;
    }
};

Inflamer.prototype.hasMistakes = function () {
    return this.wasMistake;
};