/**
 * Created by iamso on 20.07.2020.
 */

var KnockoutPlayersView = cc.Node.extend({
    ctor: function (competition, round) {
        this._super();

        this.competition = competition;

        this.setAnchorPoint(0.5, 0.5);
        this.updateSize();

        this.user = competition.player;
        this.opponent = competition.getPlayerById(round.getOpponentId(connector.platform.getUserID()));

        this.userView = new KnockoutPlayerView(this.user, this.getContentSize());
        this.addChild(this.userView);

        this.opponentView = new KnockoutPlayerView(this.opponent, this.getContentSize());
        this.addChild(this.opponentView);

        this.userView.opponentView = this.opponentView;
        this.opponentView.opponentView = this.userView;

        this.setupChildren();

        this.competition.onChangeResultsListeners.playersView = this.createListener(this.resultsChanged.bind(this));
        this.competition.onGetOpponent = this.createListener(function () {
            return this.opponent;
        }.bind(this));
        this.competition.onGetUserView = this.createListener(function () {
            return this.userView;
        }.bind(this));
    },

    updateSize: function () {
        var styles = cleverapps.styles.KnockoutPlayersView;
        this.setContentSize2(styles.size);
    },

    setupChildren: function () {
        if (this.top === undefined) {
            this.top = this.userView;
            this.bottom = this.opponentView;
        }
        var styles = cleverapps.styles.KnockoutPlayersView;

        var sceneSize = cleverapps.resolution.getSceneSize();
        var padding = cleverapps.styles.GameScene.padding;

        if ((this.width + padding) > sceneSize.width) {
            this.setScale(sceneSize.width / (this.width + padding));
        }

        this.setPositionRound(styles.position);
        this.top.setPositionRound(styles.views.left);
        this.bottom.setPositionRound(styles.views.right);
    },

    getViewPos: function (view) {
        var styles = cleverapps.styles.KnockoutPlayersView.views;
        return view.player.player ? styles.left : styles.right;
    },

    setViewsOrder: function (views) {
        var top = this.top = views[0];
        var bottom = this.bottom = views[1];

        if (top.y < bottom.y) {
            top.setLocalZOrder(1);
            bottom.setLocalZOrder(0);

            top.stopAllActions();
            bottom.stopAllActions();

            top.runAction(new cc.Spawn(
                new cc.CallFunc(function () {
                    top.setLocalZOrder(top.getLocalZOrder() + 1);
                }),
                new cc.Sequence(
                    new cc.ScaleTo(0.2, 1.1),
                    new cc.DelayTime(0.1),
                    new cc.ScaleTo(0.2, 1)
                ),
                new cc.MoveTo(0.5, bottom.x, bottom.y),
                new cc.CallFunc(function () {
                    top.setLocalZOrder(top.getLocalZOrder() + 1);
                })
            ));

            bottom.runAction(new cc.Spawn(
                new cc.Sequence(
                    new cc.ScaleTo(0.2, 0.9),
                    new cc.DelayTime(0.1),
                    new cc.ScaleTo(0.2, 1)
                ),
                new cc.MoveTo(0.5, top.x, top.y)
            ));
        }
    },

    resultsChanged: function (id) {
        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            this.competition.calcPlace();

            var views = [this.userView, this.opponentView];
            views.sort(function (v1, v2) {
                return this.competition.results.indexOf(v1.player) - this.competition.results.indexOf(v2.player);
            }.bind(this));

            this.setViewsOrder(views);
        }

        var userPoints = this.user.amount;
        var opponentPoints = this.opponent.amount;
        var totalPoints = this.competition.options.algo.maxResult;

        if (id === this.user.id) {
            this.userView.checkPlayerPointOpened();
            if (userPoints === opponentPoints + 1) {
                cleverapps.audio.playSound(bundles.game.urls.player_overtook_effect);
            }
        } else {
            this.opponentView.checkPlayerPointOpened();

            if (opponentPoints === userPoints + 1) {
                cleverapps.audio.playSound(bundles.game.urls.enemy_overtook_effect);
            }
            if (opponentPoints + 1 === totalPoints) {
                cleverapps.audio.playSound(bundles.game.urls.enemy_last_word_effect);
            }
        }

        var isVisible = userPoints < opponentPoints || (userPoints + 1 === totalPoints && opponentPoints + 1 === totalPoints);

        if (isVisible) {
            this.userView.runUserBehindAnimation();
        } else {
            this.userView.stopUserBehindAnimation();
        }
    },

    runForceAnimation: function () {
        this._forceAnimationRunning = true;

        var animation = function () {
            if (!this._forceAnimationRunning) {
                return;
            }

            this.player = new ActionPlayer([
                function (f) {
                    this.userView.runForceAvatarAnimation(f);
                }.bind(this),
                function (f) {
                    this.opponentView.runForceAvatarAnimation(f);
                }.bind(this),
                function (f) {
                    this.userView.runForceWordsAnimation(f);
                }.bind(this),
                function (f) {
                    this.opponentView.runForceWordsAnimation(function () {
                        f();
                        animation();
                    });
                }.bind(this)
            ]);

            this.player.play();
        }.bind(this);

        animation();
    },

    stopForceAnimation: function () {
        this._forceAnimationRunning = false;
        this.player.stop();
        this.userView.stopAllActions();
        this.opponentView.stopAllActions();
    },

    hide: function () {
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
            new cc.Hide()
        ));
    }
});

cleverapps.styles.KnockoutPlayersView = {
    views: {
        left: [{
            x: { align: "left" },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "top" }
        }, {
            x: { align: "left" },
            y: { align: "center" }
        }],
        right: [{
            x: { align: "right" },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "bottom" }
        }, {
            x: { align: "right" },
            y: { align: "center" }
        }]
    },

    size: [
        { width: 800, height: 115 },
        { width: 430, height: 260 },
        { width: 890, height: 115 }
    ],

    position: [{
        x: { align: "center" },
        y: { align: "top", dy: -140 }
    }, {
        x: { align: "left" },
        y: { align: "center", dy: 200 }
    }, {
        x: { align: "center" },
        y: { align: "top", dy: -160 }
    }],
    finger: false,
    bgOffset: 0
};
