/**
 * Created by vladislav on 19.02.2020
 */

var KnockoutPlayersView = cc.Node.extend({
    avoidNode: "PlayersView",

    ctor: function (competition, round) {
        this._super();

        this.competition = competition;
        this.user = competition.player;
        this.opponent = competition.getPlayerById(round.getOpponentId(connector.platform.getUserID()));

        this.createPlayers();

        this.setAnchorPoint(0.5, 0.5);
        this.updateSize();
        this.setupChildren();

        this.competition.onChangeResultsListeners.playersView = this.createListener(this.resultsChanged.bind(this));
    },

    createPlayers: function () {
        var scene = cleverapps.scenes.getRunningScene();

        this.userView = new KnockoutPlayerView(this.user);
        scene.addChild(this.userView);
        this.userView.setPositionRound(cleverapps.styles.KnockoutPlayersView.user);

        this.opponentView = new KnockoutPlayerView(this.opponent);
        scene.addChild(this.opponentView);
        this.opponentView.setPositionRound(cleverapps.styles.KnockoutPlayersView.opponent);
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.setContentSize2(sceneSize.width, this.userView.height);
        } else {
            this.setContentSize2(this.userView.width, sceneSize.height);
        }
    },

    setupChildren: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.setPositionRound(sceneSize.width / 2, this.userView.y);
        } else {
            this.setPositionRound(this.userView.x, sceneSize.height / 2);
        }
    },

    resultsChanged: function (id) {
        var userPoints = this.user.amount;
        var opponentPoints = this.opponent.amount;
        var totalPoints = this.competition.options.algo.maxResult;

        if (id === this.user.id) {
            this.userView.checkPlayerPointOpened();
            if (userPoints === opponentPoints + 1) {
                cleverapps.audio.playSound(bundles.game.urls.player_overtook_effect);
            }
        } else {
            this.opponentView.checkPlayerPointOpened();

            if (opponentPoints === userPoints + 1) {
                cleverapps.audio.playSound(bundles.game.urls.enemy_overtook_effect);
            }
            if (opponentPoints + 1 === totalPoints) {
                cleverapps.audio.playSound(bundles.game.urls.enemy_last_word_effect);
            }
        }

        var isVisible = userPoints < opponentPoints || (userPoints + 1 === totalPoints && opponentPoints + 1 === totalPoints);

        if (isVisible) {
            this.userView.runUserBehindAnimation();
        } else {
            this.userView.stopUserBehindAnimation();
        }
    },

    runForceAnimation: function () {
        this._forceAnimationRunning = true;

        var animation = function () {
            if (!this._forceAnimationRunning) {
                return;
            }

            this.player = new ActionPlayer([
                function (f) {
                    this.userView.runForceAvatarAnimation(f);
                }.bind(this),
                function (f) {
                    this.opponentView.runForceAvatarAnimation(f);
                }.bind(this)
            ]);

            this.player.play();
        }.bind(this);

        animation();
    },

    stopForceAnimation: function () {
        this._forceAnimationRunning = false;
        this.player.stop();
        this.userView.stopAllActions();
        this.opponentView.stopAllActions();
    }
});

cleverapps.styles.KnockoutPlayersView = {
    user: [
        { x: { align: "left", dx: 10 }, y: { align: "top", dy: -125 } },
        { x: { align: "left", dx: 10 }, y: { align: "top", dy: -150 } },
        { x: { align: "left", dx: 10 }, y: { align: "top", dy: -150 } }
    ],
    opponent: [
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -125 } },
        { x: { align: "left", dx: 10 }, y: { align: "top", dy: -350 } },
        { x: { align: "left", dx: 10 }, y: { align: "top", dy: -350 } }
    ]
};
